import React from "react"
import EyeBg from "../assets/eye-bg.svg"
import "./content-section.css"

function ContentSection({ title, content }) {
  return (
    <section className="bg-gold">
      <div className="flex flex-wrap items-center justify-center px-6 pt-14 max-w-7xl mx-auto relative overflow-hidden content-area">
        <h2 className="text-white uppercase text-center mb-5 tracking-widest font-medium">
          {title}
        </h2>
        <p className="text-brown text-4xl font-bold text-center">
          {content}
        </p>
        <div class="eye-bg absolute w-3/4">
          <EyeBg />
        </div>
      </div>
    </section>
  )
}

export default ContentSection
