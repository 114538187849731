import React from "react"
import EyeGold from "../assets/eye-gold.svg"
import styles from "./new-hero.css"
import ScrollDown from "../assets/scrolldown.svg"

function NewHero({ siteTitle }) {
  return (
    <section className="bg-blue border-t-2 border-red">
      <div className="flex flex-wrap flex-col items-start justify-between px-6 pb-20 pt-14 max-w-7xl mx-auto relative">
        <div className="text-white text-2xl md:text-3xl lg:text-4xl font-bold mb-10">
          Welcome to Sun Valley
        </div>
        <div className="flex items-center flex-shrink-0">
          <span className="text-gold font-semibold site-hero items-center uppercase" >
            <span className="mr-1">Stay</span>
            <EyeGold className="hero-eye-svg-el" /><br/>
            <span className="ml-1">Sunny</span>
          </span>
        </div>
        <div className="scroll-down w-12 md:w-24 lg:w-32 absolute bottom-16 right-4">
          <ScrollDown />
        </div>
      </div>
    </section>
  )
}

export default NewHero
