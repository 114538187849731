import React from "react"

import NewHero from "../components/new-hero"
import ContentSection from "../components/content-section"
import Slider from "../components/slider"
import Video from "../components/video"
import Layout from "../components/layout"
import Sign from "../components/sign"
import SEO from "../components/seo"
import "../styles/misc.css"

const IndexPage = () => {

  const signMessages1 = [
    `Sun Valley is understaffed.`,
    `So when you visit...`,
    `Be Kind.`,
    `Be patient.`,
    `Our staff is working their tail off.`,
    `Literally.`
  ];

  const signMessages2 = [
    `Sun Valley is filling up.`,
    `So when planning your trip...`,
    `Find your thing.`,
    `Book it today.`,
    `Don't wait for fate.`,
    `You'll win in the end.`
  ];

  const signMessages3 = [
    `Sun Valley is booming.`,
    `So when you arrive...`,
    `Use common sense.`,
    `Wear a mask indoors.`,
    `Keep your space.`,
    `Enjoy the fresh air.`
  ];

  const contentTitle = "A few things you should know";
  const contentParagraph = "The mission of the Stay Sunny Society is to celebrate and protect the things we love most about Sun Valley. This Society is not run by an eccentric billionaire or international cabal. Instead, it is the collective voice of the Valley: its servers, bartenders, cops, hoteliers, townies, tourists, and Regular Joes. ONE community bound together, by kindness and respect. For our fellow humans and this beautiful Valley we call home."

  return (
    <Layout>
      <SEO title="Stay Sunny" />
      <NewHero />

      <div className="bg-blue">
        <Video />
      </div>

      <ContentSection title={contentTitle} content={contentParagraph} />

      <Slider />
    </Layout>
  );
}

export default IndexPage
