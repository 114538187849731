import PropTypes from "prop-types"
import React from "react"

import styles from "./sign.css"

function Sign({messages, animationOffset, background}) {

  const bagroundClass = `bg-${background}`;
  const textClass = background === 'red' || background === 'gold' ? `text-brown` : 'text-gold';
  const signNodes = messages.map((message, index) => {
    return <div key={`message-${index}`} className={`${bagroundClass} sign-outer rounded p-1 m-2`}>
      <div className={`sign-inner font-bebas p-1 rounded-sm text-2xl ${textClass}`}>
        {message}
      </div>
    </div>
  })

  return (
    <div className="p-6" data-aos="sign-fold-down" data-aos-desktop-delay={animationOffset} data-aos-anchor-placement="bottom-bottom">
      {signNodes}
    </div>
  )
}

Sign.propTypes = {
  messages: PropTypes.array,
  background: PropTypes.oneOf(['red', 'gold', 'blue', 'brown']),
  animationDelay: PropTypes.number
}

Sign.defaultProps = {
  messages: [
    `Sun Valley is understaffed`,
    `So when you vist...`,
    `Be Kind`,
    `Be patient`,
    `Our staff is working thier tail off.`,
    `Literally`
  ],
  background: 'red',
  animationDelay: 0
}

export default Sign
