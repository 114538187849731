import React from "react"
import 'plyr-react/dist/plyr.css'
import loadable from '@loadable/component'

const Plyr = loadable(() => import("plyr-react"))

function Video() {
  return (
    <div className="bg-blue">
      <Plyr
        source={
          {
            type: 'video',
            title: 'Stay Sunny',
            sources: [
              {
                src: '4rnQT7TM6aE',
                provider: 'youtube',
              },
            ]
          }
        }
        options={
          {
            /* https://github.com/sampotts/plyr#options */
          }
        }
        {
          ...{
            /* Direct props for inner video tag (mdn.io/video) */
          }
        }
      />
    </div>
  )
}

export default Video
