import React from "react"
import {Navigation} from "swiper"
import {Swiper, SwiperSlide} from "swiper/react"
import PropTypes from "prop-types"

import 'swiper/css';
import 'swiper/css/navigation';

import './slider.css';
import PointerLeft from "../assets/pointer-left.svg"
import PointerRight from "../assets/pointer-right.svg"

function Slider({images}) {

  const slides = images.map((image) => {
    return (<SwiperSlide>
              <img className="swiper__img" src={`./slider/${image}`} />
            </SwiperSlide>);
  })

  const swiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 10,
    navigation: {
      prevEl: ".swiper-pointer-prev",
      nextEl: ".swiper-pointer-next"
    },
  }

  return (
    <div className="bg-red">
      <div className="py-10 px-20 max-w-7xl mx-auto relative">
        <Swiper modules={[Navigation]} {...swiperOptions}>
          {slides}
        </Swiper>
        <div className="swiper-pointer-prev">
          <PointerLeft className="swiper-pointer-svg" />
        </div>
        <div className="swiper-pointer-next">
          <PointerRight className="swiper-pointer-svg" />
        </div>
      </div>
    </div>
  )
}

Slider.propTypes = {
  images: PropTypes.array,
}

Slider.defaultProps = {
  images: [
    'Bus_IMG_1543.jpg',
    'Chalk Art_Gadd_Ray_J_O4A1468.jpg',
    'Miscellany II - 21-07-15.jpg',
    'Miscellany II - 2021-08-18-IMG_2297.jpg',
    'Sawtooth Brewery 1.jpg',
    'Window Cling_Gadd_Ray_J_O4A1164.jpg'
  ],
}


export default Slider
